import type { LoginResponse } from "@/types/api";
import { axios } from "../axios";

export const authenticateByEmail = (email: string) => {
  return axios.post<undefined>("api/Auth/authenticate", { email });
};

export const authenticateWithEmailAndCode = (email: string, code: string) => {
  return axios
    .post<LoginResponse>("api/Auth/authenticate-with-code", {
      email,
      code
    })
    .then(response => response.data);
};

export const logout = () => {
  return axios.post<undefined>("api/Auth/logout");
};

export const refreshAuthTokens = (refreshToken: string) => {
  return axios
    .post<LoginResponse>("api/Auth/refresh-token", { refreshToken })
    .then(response => response.data);
};
