import type { GetProjectDataResponse } from "@/types";
import type { AuthUserResponse, CreateProjectResponse } from "@/types/api";
import { axios, useCache } from "../axios";
import { SearchOperation, SearchCondition, SortOrder } from "@/constants";
import { useUserStore } from "@/stores/user";
import type { CacheRequestConfig } from "axios-cache-interceptor";

export const getProjectData = (
  projectId: Guid,
  config: CacheRequestConfig<GetProjectDataResponse> = {}
) => {
  return axios
    .get<GetProjectDataResponse>("api/data/internal/Project/projectData", {
      params: { projectId },
      cache: useCache({
        ttl: 15 * 60 * 1000,
        update: {
          // Someone else might have changed some data for this building
          // so we need to update the building in the userStore and user cache
          // when we retrieve the projectData
          user: (userCacheResponse, projectDataResponse) => {
            if (userCacheResponse.state !== "cached") return "ignore";

            const userCache = userCacheResponse.data.data as AuthUserResponse;
            const buildings = userCache.data.buildingAccessInformation;

            const buildingId = projectDataResponse.data.data.buildings[0].id;

            const index = buildings.findIndex(
              ({ buildingInformation }) => buildingInformation.id === buildingId
            );

            if (index < 0) {
              return "delete";
            }

            // Update cache
            buildings[index].buildingInformation = projectDataResponse.data.data.buildings[0];

            // Update user store
            const user = useUserStore();
            user.updateBuilding(buildingId, buildings[index].buildingInformation);

            return userCacheResponse;
          }
        }
      }),
      ...config
    })
    .then(response => response.data);
};

export const searchProjects = (
  pageNumber = 1,
  pageSize = 50,
  field = "name",
  order = SortOrder.Ascending,
  query = ""
) => {
  // TODO: Once the api is modified to work even when the id is not a valid guid, this block should be removed
  const criteria =
    query.length == 0
      ? []
      : [
          {
            field: "id",
            value: query,
            operation: SearchOperation.Equal,
            condition: SearchCondition.Or
          }
        ];

  return axios
    .post("api/administration/Project/search", {
      pageNumber,
      pageSize,
      criteria,
      field,
      order
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return {
        succeeded: false,
        status: error.response ? error.response.status : "",
        statusText: error.response ? error.response.statusText : ""
      };
    });
};

export const createProject = (name: string, referenceId: string) => {
  return axios
    .post<CreateProjectResponse>("api/administration/Project", {
      name,
      referenceId
    })
    .then(response => response.data);
};

export const updateProject = (projectId: Guid, name: string, referenceId: string) => {
  return axios
    .put("api/administration/Project", { id: projectId, name, referenceId })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return {
        succeeded: false,
        status: error.response ? error.response.status : "",
        statusText: error.response ? error.response.statusText : ""
      };
    });
};
