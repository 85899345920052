// Always use a prefix to avoid coincidence with existing icons.

interface Icon {
  name: string;
  style: string;
  path: string;
}

export const hwGoogleMaps: Icon = {
  name: "google-maps",
  style: "line",
  path: '<g clip-path="url(#clip0_2598_30160)"><path d="M13.8602 1.33679C13.0965 1.09583 12.2781 0.963989 11.4325 0.963989C8.96389 0.963989 6.7544 2.07783 5.27686 3.8327L9.073 7.02421L13.8602 1.33679Z" fill="#1A73E8"/><path d="M5.27695 3.83264C4.05988 5.27819 3.39319 7.10756 3.39478 8.99724C3.39478 10.5066 3.69483 11.7341 4.19038 12.8298L9.07312 7.02414L5.27695 3.83264Z" fill="#EA4335"/><path d="M11.4326 5.92851C12.0187 5.92905 12.5923 6.09704 13.0861 6.41269C13.5799 6.72835 13.9732 7.17852 14.2197 7.71021C14.4661 8.24189 14.5556 8.83294 14.4774 9.41374C14.3993 9.99455 14.1568 10.5409 13.7785 10.9885L18.5612 5.30113C17.5795 3.40975 15.8871 1.98548 13.8558 1.34131L9.07764 7.02872C9.36554 6.68393 9.72572 6.40666 10.1327 6.21654C10.5396 6.02641 10.9834 5.92808 11.4326 5.92851Z" fill="#4285F4"/><path d="M11.4326 12.075C9.73679 12.075 8.35926 10.6975 8.35926 9.00176C8.35727 8.27972 8.61185 7.58045 9.07758 7.02869L4.19031 12.8343C5.02683 14.6846 6.41345 16.1712 7.84098 18.0443L13.7784 10.9885C13.49 11.3293 13.1306 11.6031 12.7255 11.7908C12.3203 11.9784 11.8791 12.0754 11.4326 12.075Z" fill="#FBBC04"/><path d="M13.6603 19.9675C16.3426 15.7758 19.4659 13.8709 19.4659 8.99724C19.4659 7.66062 19.1386 6.40129 18.5612 5.29199L7.84558 18.0444C8.30021 18.64 8.75939 19.2719 9.20493 19.972C10.8325 22.4907 10.3824 24.0001 11.4326 24.0001C12.4828 24.0001 12.0327 22.4861 13.6603 19.9675Z" fill="#34A853"/></g><defs><clipPath id="clip0_2598_30160"><rect width="24" height="24" fill="white"/></clipPath></defs>'
};

export const hwBAG: Icon = {
  name: "bag",
  style: "line",
  path: '<g clip-path="url(#clip0_6396_34902)"><path d="M7.99672 14.7936L12.293 0H8.93993L3.00256 20.3504L7.99672 14.7936Z" fill="#00889E"/><path d="M13.0022 9.29041L10.7444 11.7887L17.4469 24H21.3622L13.0022 9.29041Z" fill="#00387D"/></g><defs><clipPath id="clip0_6396_34902"><rect width="18.3648" height="24" fill="white" transform="translate(3)"/></clipPath></defs>'
};

export const uniPlusCircleSolid: Icon = {
  name: "plus-circle",
  style: "solid",
  path: '<path d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10C22,6.5,17.5,2,12,2z M16,13h-3v3c0,0.6-0.4,1-1,1s-1-0.4-1-1v-3H8c-0.6,0-1-0.4-1-1s0.4-1,1-1h3V8c0-0.6,0.4-1,1-1s1,0.4,1,1v3h3c0.6,0,1,0.4,1,1S16.6,13,16,13z">'
};
