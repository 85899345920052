import Unicon from "vue-unicons";
import * as Icons from "vue-unicons/dist/icons";
import * as CustomIcons from "./custom-icons";

Unicon.add([
  Icons.uniDirection,
  Icons.uniBuilding,
  Icons.uniBars,
  Icons.uniSlidersVAlt,
  Icons.uniSignOutAlt,
  Icons.uniSearch,
  Icons.uniConfused,
  Icons.uniCalender,
  Icons.uniUser,
  Icons.uniExclamationOctagon,
  Icons.uniSitemap,
  Icons.uniAngleDown,
  Icons.uniInfoCircle,
  Icons.uniAngleLeftB,
  Icons.uniAngleRightB,
  Icons.uniTemperatureHalf,
  Icons.uniTear,
  Icons.uniWind,
  Icons.uniFocus,
  Icons.uniExternalLinkAlt,
  Icons.uniTrashAlt,
  Icons.uniChartPieAlt,
  Icons.uniSun,
  Icons.uniUsersAlt,
  Icons.uniUserPlus,
  Icons.uniAngleLeft,
  Icons.uniAngleRight,
  Icons.uniEdit,
  Icons.uniBookmarkFull,
  Icons.uniExclamationTriangle,
  Icons.uniElipsisDoubleVAlt,
  Icons.uniChannel,
  Icons.uniGlobe,
  Icons.uniEllipsisH,
  Icons.uniEllipsisV,
  Icons.uniVolume,
  Icons.uniSignal,
  Icons.uniTimes,
  Icons.uniCheck,
  Icons.uniPlug,
  Icons.uniWrench,
  Icons.uniChart,
  Icons.uniSave,
  Icons.uniVectorSquare,
  Icons.uniEnter,
  Icons.uniLayerGroup,
  Icons.uniArrowUp,
  Icons.uniArrowDown,
  Icons.uniAngleUp,
  Icons.uniBoltAlt,
  Icons.uniShieldCheck,
  Icons.uniSetting,
  Icons.uniLaptopConnection,
  Icons.uniLockAlt,
  Icons.uniArrowGrowth,
  Icons.uniChartDown,
  Icons.uniListUl,
  Icons.uniFilter,
  Icons.uniImport,
  Icons.uniRefresh,
  Icons.uniCopy,
  Icons.uniSync,
  Icons.uniPlus,
  Icons.uniUpload,
  Icons.uniFolder,
  Icons.uniEye,
  Icons.uniEyeSlash,
  Icons.uniTagAlt,
  Icons.uniFile,
  Icons.uniHistory,
  Icons.uniMapMarker,
  Icons.uniMap,
  Icons.uniFire,
  Icons.uniSpinner,
  Icons.uniLabel,
  Icons.uniBook,
  Icons.uniTag,
  Icons.uniNA,
  Icons.uniEnvelope,
  Icons.uniCheckCircle,
  Icons.uniTimesCircle,
  Icons.uniSchedule,
  CustomIcons.hwGoogleMaps,
  CustomIcons.hwBAG,
  CustomIcons.uniPlusCircleSolid,
  Icons.uniRobot
]);

export { Unicon };
