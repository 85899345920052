// This file is automatically generated by the 'generate-constants' script.

export enum License {
  ESG = 0,
  Health = 1,
  Bms = 2
}

export enum SensorType {
  Temperature = "temp",
  Humidity = "rv",
  CarbonDioxide = "co2",
  CarbonMonoxide = "co",
  VOC = "tvoc",
  PM1 = "pm1",
  PM4 = "pm4",
  PM10 = "pm10",
  PM25 = "pm25",
  Formaldehyde = "formaldehyde",
  Sound = "soundAvg",
  Ozone = "ozon",
  NitrogenDioxide = "nitrogenDioxide"
}

export enum UtilityType {
  Electricity = "Electricity",
  ElectricityReturn = "Electricity return",
  ElectricityGenerated = "Electricity generated",
  Gas = "Gas",
  DistrictHeating = "District heating",
  DistrictCooling = "District cooling",
  Heating = "Heating",
  Cooling = "Cooling",
  Water = "Water"
}

export enum BuildingType {
  Office = "Office",
  Healthcare = "Healthcare",
  IndustrialDistributionWarehouse = "Industrial Distribution Warehouse"
}

export enum AreaType {
  Apartment = "Apartment",
  ApartmentBuilding = "ApartmentBuilding",
  Cafe = "Cafe",
  Childcare = "Childcare",
  GarageShowroom = "GarageShowroom",
  GroundFloorApartment = "GroundFloorApartment",
  HealthcarePractice = "HealthcarePractice",
  HoldingCell = "HoldingCell",
  HolidayPark = "HolidayPark",
  Hospital = "Hospital",
  Hotel = "Hotel",
  MeetingArea = "MeetingArea",
  Office = "Office",
  RefrigeratedStorage = "RefrigeratedStorage",
  Restaurant = "Restaurant",
  Sauna = "Sauna",
  School = "School",
  ShelterWithoutSleeping = "ShelterWithoutSleeping",
  ShelterWithSleeping = "ShelterWithSleeping",
  SportAreaInside = "SportAreaInside",
  SportAreaOutside = "SportAreaOutside",
  StoreWithoutRefrigeration = "StoreWithoutRefrigeration",
  StoreWithRefrigeration = "StoreWithRefrigeration",
  SwimmingPool = "SwimmingPool",
  University = "University",
  Warehouse = "Warehouse"
}

export enum TenancyType {
  SingleTenant = 0,
  MultiTenant = 1
}

export enum Period {
  QuarterHour = "15min",
  Hour = "hour",
  Day = "day",
  Week = "week",
  Month = "month",
  Year = "year"
}

export enum MeterPosition {
  Main = 0,
  Sub = 1
}

export enum Holiday {
  NewYearsEveAndDay = "New Years Eve & New Years Day",
  GoodFridayAndEaster = "Good Friday & Easter",
  KingsDay = "Kings Day",
  LiberationDay = "Liberation Day",
  AscensionDayAndPentecost = "Ascention Day & Pentecost",
  Christmas = "Christmas"
}

export enum FileTag {
  Contract = 0,
  Export = 1,
  Floorplan = 2,
  Mandate = 3,
  Measurements = 4,
  Report = 5,
  Sensoring = 6,
  Other = 7
}

export enum Language {
  en_US = "en-US",
  nl_NL = "nl-NL"
}

export enum SearchOperation {
  Equal = 1,
  NotEqual = 2,
  Greater = 3,
  GreaterOrEqual = 4,
  Less = 5,
  LessOrEqual = 6,
  Contains = 7
}

export enum SearchCondition {
  And = 1,
  Or = 2,
  Not = 3
}

export enum SortOrder {
  None = 0,
  Ascending = 1,
  Descending = 2
}

export enum SpaceUsageType {
  EnclosedOffice = "ENCLOSED OFFICE",
  OpenWorkspace = "OPEN WORKSPACE",
  Entrance = "ENTRANCE",
  MeetingRoom = "MEETING ROOM",
  PersonalDesk = "PERSONAL DESK",
  Cantine = "CANTINE"
}

export enum MediaRelationType {
  Building = "BUILDING",
  Floor = "FLOOR"
}

export enum MediaFileType {
  Cover = "COVER",
  Logo = "LOGO",
  Floorplan = "FLOORPLAN"
}

export enum ProductModuleHostName {
  HealthyWorkers = "HealthyWorkers",
  InfinityHost = "Infinity"
}

export enum ProductModuleType {
  ESG = "Esg",
  Health = "Health",
  Bms = "SmartBuilding",
  Weather = "Weather"
}

export enum InviteStatus {
  Accepted = "Accepted",
  Pending = "Pending",
  Rejected = "Rejected",
  Revoked = "Revoked"
}

export enum ConnectionCategory {
  None = "None",
  ChargingStation = "ChargingStation",
  Garage = "Garage",
  Industrial = "Industrial",
  NonSpaceHeating = "NonSpaceHeating"
}

export enum Role {
  Admin = "Admin",
  User = "User"
}

export enum AccessLevel {
  Admin = "Admin",
  BuildingUser = "BuildingUser",
  BuildingAccessGroupUser = "BuildingAccessGroupUser",
  LoggedIn = "LoggedIn",
  Public = "Public"
}

export enum SystemType {
  AirHandlingUnit = "ahu",
  Boiler = "boiler",
  Chiller = "chiller",
  HeatPump = "heatPump",
  PrimaryLoop = "primaryLoop",
  SecondaryLoop = "secondaryLoop"
}

export enum DayOfWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6
}

export enum DatePreset {
  Custom = "custom",
  Today = "today",
  Yesterday = "yesterday",
  ThisWeek = "this-week",
  LastWeek = "last-week",
  LastMonth = "last-month",
  Last28Days = "last-28-days",
  LastQuarter = "last-quarter",
  Last90Days = "last-90-days",
  ThisYear = "this-year",
  LastYear = "last-year"
}

export const Unit: Readonly<Record<string, string>> = {
  Electricity: "kWh",
  ElectricityReturn: "kWh",
  ElectricityGenerated: "kWh",
  Gas: "m³",
  DistrictHeating: "GJ",
  DistrictCooling: "GJ",
  Heating: "GJ",
  Cooling: "GJ",
  Water: "m³",
  Temperature: "°C",
  Humidity: "%",
  CarbonDioxide: "ppm",
  CarbonMonoxide: "μg/m3",
  VOC: "μg/m3",
  PM1: "μg/m3",
  PM4: "μg/m3",
  PM10: "μg/m3",
  PM25: "μg/m3",
  Formaldehyde: "μg/m3",
  Sound: "dB",
  Ozone: "μg/m3",
  NitrogenDioxide: "ppb"
};

export const UnitDecimals: Readonly<Record<string, number>> = {
  Electricity: 0,
  ElectricityReturn: 0,
  ElectricityGenerated: 0,
  Gas: 1,
  DistrictHeating: 2,
  DistrictCooling: 2,
  Heating: 2,
  Cooling: 2,
  Water: 1
};

export enum UtilityTypesInfinity {
  Electricity = "electricity",
  ElectricityReturn = "electricityReturned",
  ElectricityGenerated = "electricityGenerated",
  Gas = "gas",
  DistrictHeating = "districtHeating",
  DistrictCooling = "districtCooling",
  Heating = "heating",
  Cooling = "cooling",
  Water = "water"
}

export enum RealizedSavingsPriceOptions {
  CBS = "CBS",
  Custom = "Custom"
}

export enum CBSPriceType {
  Total = "A047656",
  Supply = "A047657",
  Network = "A047658"
}

export enum CBSVATAndTaxType {
  VATAndTax = "A047659",
  Tax = "A047660",
  None = "A047661"
}

export enum GCV {
  Gas = 35.17
}

export enum WEiiClass {
  VeryInefficient = "Zeer onzuinig",
  Inefficient = "Onzuinig",
  Average = "Gemiddeld",
  Efficient = "Zuinig",
  VeryEfficient = "Zeer zuinig",
  ParisProof = "Paris Proof",
  Weng = "WENG"
}

export enum WEiiEnergyType {
  Electricity = "Elektriciteit",
  Gas = "Aardgas",
  Heating = "Warmte",
  Cooling = "Koude",
  BioMass = "Biomassa",
  Hydrogen = "Waterstof",
  Oil = "Olie",
  PropaneGas = "Propaangas"
}
