<template>
  <Toast position="top-right" :auto-z-index="false">
    <template #icon="{ class: iconClass }">
      <unicon :name="getIconName(iconClass)" :class="iconClass" />
    </template>

    <template #closeicon="{ class: iconClass }">
      <unicon name="times" :class="iconClass" />
    </template>
  </Toast>
</template>

<script setup lang="ts">
import Toast from "primevue/toast";

const icons: Record<string, string> = {
  info: "info-circle",
  success: "check",
  warn: "exclamation-octagon",
  error: "times"
};

// Hopefully temporary till the icon slot exposes 'severity'
function getIconName(iconClass: string) {
  const severity = iconClass
    .split(" ")
    .filter(item => ["info", "success", "warn", "error"].includes(item))[0];

  return icons[severity];
}
</script>
